import React from 'react';
import bemCn from 'bem-cn';
import CookieService from '../../services/cookie.service';
import { PARK_TYPES } from '../../consts/type-park.consts';
import { SIGN_UP_SRC } from '../../consts/spot.consts';
import { RouteFormatter } from '../../routes';
import { smartBannerShow } from '../../utils';
import { savePathRedirect } from '../../helpers/cookies';
import { COOKIE_PARAM_NAME } from '../../consts/cookies.consts';
import { blogs, mainMenu, topBlogs, topCities, topDogNames, topDogRescues, trainers } from './consts';
import SniffButton from '../sniff-button';
import PictureSet from '../picture-set';
import SvgChevronDown from 'src/assets/svg/SvgChevronDown.svg';
import SvgChevronUp from 'src/assets/svg/SvgChevronUp.svg';
import './mobile.scss';

const b = bemCn('nav-guest-mobile-unauth');

const RenderDogParks = () => (
    <div className={b('collapse')}>
        <h3 className="snif-p snif-semibold">Top cities</h3>
        {topCities.map((v, i) => (
            <a key={i} href={RouteFormatter.listings({ first: v.url })} className="d-block mt-3">
                {v.name}
            </a>
        ))}
        <a href={RouteFormatter.listings({})} className="d-block mt-3 mb-5">
            <span className="snif-p text-underline">More locations...</span>
        </a>
        <h3 className="snif-p snif-semibold pt-1">Park types</h3>
        {PARK_TYPES.map((v, i) => (
            <a key={i} href={v.url} className="d-block mt-3">
                {v.text}
            </a>
        ))}
    </div>
);

const RenderBlogs = ({ useState }) => (
    <div className={b('collapse')}>
        {topBlogs.map((v, i) => (
            <a key={i} href={v.url} target="_blank" rel="noopener noreferrer" className="d-block mb-3">
                {v.title}
            </a>
        ))}
        <h3 className="snif-p snif-semibold mt-5">Top articles</h3>
        {blogs.map((v, i) => (
            <a key={i} href={v.url} target="_blank" rel="noopener noreferrer" className={b('blog-post')}>
                <PictureSet imgs={v.img} width={48} height={48} alt={`${v.title} thumbnail`} useState={useState} SSR />
                <p className="snif-s1">{v.title}</p>
            </a>
        ))}
        <a href={RouteFormatter.blog()} target="_blank" rel="noopener noreferrer" className="d-block text-end mt-5 pt-2">
            <span className="snif-p text-underline">See all...</span>
        </a>
    </div>
);

const RenderTrainers = () => (
    <div className={b('collapse')}>
        <h3 className="snif-p snif-semibold">Sniffspot's annual contest for top dog trainers by city and state</h3>
        {trainers.map((v, i) => (
            <a key={i} className={b('trainer-post')} href={v.url} target="_blank" rel="noopener noreferrer">
                <span className="snif-p">{v.title}</span>
            </a>
        ))}
    </div>
);

const RenderDogNames = () => (
    <div className={b('collapse')}>
        <h3 className="snif-p snif-semibold">Browse our popular dog names across genders, breeds & states</h3>
        {topDogNames.map((v, i) => (
            <a key={i} className="d-block snif-p mt-3" href={v.url} target="_blank" rel="noopener noreferrer">
                <span className="snif-p">{v.title}</span>
            </a>
        ))}
    </div>
);

const RenderDogRescues = () => (
    <div className={b('collapse')}>
        <h3 className="snif-p snif-semibold">Find top dog rescues & shelters in the United States</h3>
        {topDogRescues.map((v, i) => (
            <a key={i} className="d-block snif-p mt-3" href={v.url} target="_blank" rel="noopener noreferrer">
                <span className="snif-p">{v.title}</span>
            </a>
        ))}
    </div>
);

const NavGuestMobileUnauth = ({ active, handleLeave, useState, isListing }) => {
    const [collapse, setCollapse] = useState(null);
    const canShowSmartBanner = smartBannerShow();

    const onClick = () => {
        savePathRedirect();
        CookieService.set(COOKIE_PARAM_NAME.SIGN_UP_FROM, SIGN_UP_SRC.NAV_WEB, { expires: 1 });
    };

    const toggle = (i) => {
        if (collapse === null) {
            setCollapse(i);
        } else {
            if (collapse === i) {
                setCollapse(null);
            } else {
                setCollapse(i);
            }
        }
    };

    return (
        <div
            className={b
                .mix(active ? 'active' : '')
                .mix(isListing ? 'is-listings' : '')
                .mix(canShowSmartBanner ? 'smart-b' : '')}
        >
            {mainMenu.map((v, i) => {
                const isActiveIdx = collapse === v.id;
                return (
                    <React.Fragment key={i}>
                        <div className={b('main-menu')}>
                            {v.id === 'DP' ? (
                                <a href={v.url} onClick={handleLeave}>
                                    {v.name}
                                </a>
                            ) : (
                                <a href={v.url}>{v.name}</a>
                            )}
                            {v.id && (
                                <div className="chev-menu" onClick={() => toggle(v.id)}>
                                    {isActiveIdx ? <SvgChevronUp /> : <SvgChevronDown />}
                                </div>
                            )}
                        </div>
                        {v.id === 'DP' && (
                            <div className={b('collapse-container').mix(isActiveIdx ? 'open' : '')}>
                                <RenderDogParks />
                            </div>
                        )}
                        {v.id === 'BG' && (
                            <div className={b('collapse-container').mix(isActiveIdx ? 'open' : '')}>
                                <RenderBlogs {...{ useState }} />
                            </div>
                        )}
                        {v.id === 'DN' && (
                            <div className={b('collapse-container').mix(isActiveIdx ? 'open' : '')}>
                                <RenderDogNames />
                            </div>
                        )}
                        {v.id === 'DR' && (
                            <div className={b('collapse-container').mix(isActiveIdx ? 'open' : '')}>
                                <RenderDogRescues />
                            </div>
                        )}
                        {v.id === 'TR' && (
                            <div className={b('collapse-container').mix(isActiveIdx ? 'open' : '')}>
                                <RenderTrainers />
                            </div>
                        )}
                    </React.Fragment>
                );
            })}
            <div className="mt-5 mb-5 px-4">
                <a href={RouteFormatter.signUp()} onClick={onClick} className="d-block">
                    <SniffButton size="lg" block>
                        Sign up
                    </SniffButton>
                </a>
                <a href={RouteFormatter.signIn()} onClick={onClick} className="d-block mt-2">
                    <SniffButton style={{ border: 'none' }} color="secondary" size="lg" block>
                        Sign in
                    </SniffButton>
                </a>
            </div>
        </div>
    );
};

export default NavGuestMobileUnauth;
